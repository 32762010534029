export default class OrderLine {
	id: any;
	ec_order_id: any;
	ec_product_configuration_id: any;
	parent_ec_product_configuration_id: any;
	quantity: any;
	amount: any;
	discount_amount: any;
	vat_amount: any;
	vat_rate: any;

	constructor() {
		this.id = null;
		this.ec_order_id = '';
		this.ec_product_configuration_id = '';
		this.parent_ec_product_configuration_id = null;
		this.quantity = '';
		this.amount = '';
		this.discount_amount = '';
		this.vat_amount = [];
		this.vat_rate = [];
	}

	public static copy(model: any) {
		const aux = new OrderLine();
		aux.id = model.id;
		aux.ec_order_id = model.ec_order_id;
		aux.ec_product_configuration_id = model.ec_product_configuration_id;
		aux.parent_ec_product_configuration_id = model.parent_ec_product_configuration_id;
		aux.quantity = model.quantity;
		aux.amount = model.amount;
		aux.discount_amount = model.discount_amount;
		aux.vat_amount = model.vat_amount;
		aux.vat_rate = model.vat_rate;
		return aux;
	}
}
