




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InputSelect extends Vue {
	@Prop({ default: null, required: false }) private model: any | undefined;
	@Prop({ default: null, required: true }) private label: any | '';
	@Prop({ default: null, required: true }) private id: any | '';
	@Prop({ default: null, required: false }) private helpText: any | '';
	@Prop({ default: null, required: true }) private options: any | [];
	@Prop({ default: null, required: false }) private errors: any | [];
	@Prop({ default: null, required: false }) private valid: any | boolean;
	@Prop({ default: null, required: false }) private validFeedbackText: any | undefined;
	@Prop({ default: false, required: false }) private disabled: any | boolean;

	private value = null;

	@Watch('model')
	onModelChanged(model: any) {
		this.value = model;
	}

	mounted() {
		if (this.model) {
			this.value = this.model;
		}
	}

	get state() {
		return this.errors == null || this.errors.length == 0 ? null : this.errors.length == 0;
	}

	get invalidFeedback() {
		if (this.errors) {
			return this.errors[0];
		}
		return null;
	}

	get validFeedback() {
		if (this.validFeedbackText) {
			return !this.errors && this.valid ? this.validFeedbackText : '';
		}
		return '';
	}
}
