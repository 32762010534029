








































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Checkbox from '~/components/inputs/Checkbox.vue';
import InputText from '~/components/inputs/InputText.vue';
import UserService from '~/services/UserService';
import ApiHelper from '~/helpers/api-helper';
import InputSelect from '~/components/inputs/InputSelect.vue';

@Component({
	components: {
		InputSelect,
		Checkbox,
		InputText,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class ModalDemo extends Vue {
	selected: any = null;

	positions: Array<object> = [
		{ value: null, text: 'Selecciona una opción' },
		{ value: 'Gerencia/dirección', text: 'Gerencia/dirección' },
		{ value: 'Manager/departamento', text: 'Manager/departamento' },
		{ value: 'Empleado/a', text: 'Empleado/a' },
	];

	private privacy: boolean = false;
	private public_url = process.env.APP_URL;
	private anchor_policy = this.public_url + 'legalTerm/politicaprivacidad';
	private legalTerm = this.public_url + 'legalTerm/avisolegal';
	private data: {
		name: string;
		surname: string;
		email: string;
		phone: string;
		company: string;
		revenue: any;
		employees: any;
		postal_code: string;
		product: string;
		position: string;
		advertising: string;
	} = {
		name: '',
		email: '',
		surname: '',
		phone: '',
		company: '',
		revenue: null,
		employees: null,
		postal_code: '',
		product: '',
		position: '',
		advertising: 'F',
	};

	$refs!: {
		modal: any;
		modal_demo_obs: InstanceType<typeof ValidationObserver>;
	};

	show(product: string) {
		this.data.product = product;
		this.$refs.modal.show();
	}

	hide() {
		this.$refs.modal.hide();
	}

	onDemo() {
		this.$nuxt.$loading.start();
		UserService.bitrix(this.$axios, { type: UserService.BITRIX_DEMO_TYPE, data: this.data })
			.then((res: any) => {
				if (res.status === 200) {
					this.$nuxt.$loading.finish();
					this.hide();
					this.$store.commit('setAlert', {
						show: true,
						message: '¡Solicitud enviada! Te contactaremos lo antes posible',
						type: 'success',
					});
					if (this.$refs.modal_demo_obs) {
						this.$refs.modal_demo_obs.reset();
					}
					this.data = {
						name: '',
						surname: '',
						email: '',
						phone: '',
						company: '',
						revenue: null,
						employees: null,
						postal_code: '',
						product: '',
						position: '',
						advertising: 'F',
					};
					this.privacy = false;
				}
			})
			.catch((error: any) => {
				this.$nuxt.$loading.finish();
				ApiHelper.handleErrors(error, this.$refs.modal_demo_obs, this.$store);
			});
	}
}
