

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ProductChildren extends Vue {
	@Prop({ default: null, required: false }) private model: any | undefined;
	@Prop({ default: null, required: true }) private id: any | '';
	@Prop({ default: [], required: true }) private values: any | [];
	@Prop({ default: null, required: true }) private label: any | '';
	@Prop({ default: null, required: false }) private errors: any | [];
	@Prop({ default: null, required: false }) private valid: any | boolean;
	@Prop({ default: null, required: false }) private validFeedbackText: any | undefined;

	private value = null;

	private selectedProduct: number = 0;

	setSelectedProduct(index: number) {
		this.selectedProduct = index;
		
	}

	@Watch('model')
	onModelChanged(model: any) {
		this.value = model;
		this.setSelectedProduct(this.values.findIndex((product: any) => product.id == this.model));
	}

	mounted() {
		if (this.model) {
			this.value = this.model;
			this.setSelectedProduct(this.values.findIndex((product: any) => product.id == this.model));
		}
	}

	get state() {
		return this.errors == null || this.errors.length == 0 ? null : this.errors.length == 0;
	}

	get invalidFeedback() {
		if (this.errors) {
			return this.errors[0];
		}
		return null;
	}

	get validFeedback() {
		if (this.validFeedbackText) {
			return !this.errors && this.valid ? this.validFeedbackText : '';
		}
		return '';
	}
}
