var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"size":"lg","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"modal-heading"},[_c('p',{staticClass:"overline"},[_vm._v("solicitar información de")]),_vm._v(" "),_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.data.product))])]),_vm._v(" "),_c('b-button',{staticClass:"btn btn-close",attrs:{"size":"sm"},on:{"click":function($event){return close()}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Cerrar")])])]}}])},[_vm._v(" "),_c('p',[_vm._v("\n\t\tEnvía tu solicitud de información y nuestro departamento comercial se pondrá en contacto contigo y resolverá todas las dudas que tengas sin ningún\n\t\tcompromiso. No te preocupes, en A3 Satel tratamos toda la información personal como estrictamente confidencial. Sin spam, sin problemas.\n\t")]),_vm._v(" "),_c('div',{staticClass:"form-container"},[_c('ValidationObserver',{ref:"modal_demo_obs",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();validate(); passes(_vm.onDemo);}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"demo_name","errors":errors,"valid":valid,"label":"Nombre *","model":_vm.data.name},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"demo_surname","errors":errors,"valid":valid,"label":"Apellidos *","model":_vm.data.surname},model:{value:(_vm.data.surname),callback:function ($$v) {_vm.$set(_vm.data, "surname", $$v)},expression:"data.surname"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"demo_email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"demo_email","type":"email","errors":errors,"valid":valid,"label":"Email *","model":_vm.data.email},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"demo_phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"demo_phone","type":"tel","errors":errors,"valid":valid,"label":"Teléfono de contacto *","model":_vm.data.phone},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"demo_company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"demo_company","errors":errors,"valid":valid,"label":"Empresa *","model":_vm.data.company},model:{value:(_vm.data.company),callback:function ($$v) {_vm.$set(_vm.data, "company", $$v)},expression:"data.company"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"demo_cp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"demo_cp","errors":errors,"valid":valid,"label":"Código postal *","model":_vm.data.postal_code},model:{value:(_vm.data.postal_code),callback:function ($$v) {_vm.$set(_vm.data, "postal_code", $$v)},expression:"data.postal_code"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"employees","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"type":"number","id":"employees","errors":errors,"valid":valid,"label":"Número de empleados","model":_vm.data.employees},model:{value:(_vm.data.employees),callback:function ($$v) {_vm.$set(_vm.data, "employees", $$v)},expression:"data.employees"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"revenue","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"type":"number","id":"revenue","errors":errors,"valid":valid,"label":"Facturación anual","model":_vm.data.revenue},model:{value:(_vm.data.revenue),callback:function ($$v) {_vm.$set(_vm.data, "revenue", $$v)},expression:"data.revenue"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"position","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputSelect',{attrs:{"id":"demo_position","errors":errors,"valid":valid,"label":"Cargo","model":_vm.data.position,"options":_vm.positions},model:{value:(_vm.data.position),callback:function ($$v) {_vm.$set(_vm.data, "position", $$v)},expression:"data.position"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('b-form-group',[_c('div',{staticClass:"fields-row"},[_c('Checkbox',{attrs:{"id":"demo_advertising","model":_vm.data.advertising,"values":{ checked: 'T', unchecked: 'F' }},model:{value:(_vm.data.advertising),callback:function ($$v) {_vm.$set(_vm.data, "advertising", $$v)},expression:"data.advertising"}},[_vm._v("\n\t\t\t\t\t\t\tDeseo recibir información y promociones especiales de A3SATEL por cualquier medio, incluyendo los medios electrónicos\n\t\t\t\t\t\t")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"demo_privacy","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('Checkbox',{attrs:{"errors":errors,"valid":valid,"id":"demo_privacy","model":_vm.privacy},model:{value:(_vm.privacy),callback:function ($$v) {_vm.privacy=$$v},expression:"privacy"}},[_vm._v("\n\t\t\t\t\t\t\t\tHe leído y acepto el "),_c('a',{staticClass:"link",attrs:{"href":_vm.legalTerm,"target":"_blank"}},[_vm._v("aviso legal")]),_vm._v(" y la "),_c('a',{staticClass:"link",attrs:{"href":_vm.anchor_policy,"target":"_blank"}},[_vm._v("política de privacidad")])])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-actions"},[_c('b-button',{staticClass:"btn-lg",attrs:{"type":"submit","variant":"primary"}},[_vm._v("Enviar")])],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }