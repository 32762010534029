


































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Page from '~/models/page';
import FormatHelper from '~/helpers/format-helper';
import ModalDemo from '~/components/modals/ModalDemo.vue';
import ModalChildService from '~/components/modals/ModalChildService.vue';
import ApiHelper from '~/helpers/api-helper';
import OrderService from '~/services/OrderService';
import Order from '~/models/order';
import OrderLine from '~/models/order_line';
import GtmService from '~/services/GtmService';
import ProductChildren from "~/components/inputs/ProductChildren.vue";

@Component({
	components: {ProductChildren, ModalDemo, ModalChildService},
})
export default class PageSectionProductConfiguration extends Vue {
	@Prop({ type: Object, required: true }) content: any;
	@Prop({ required: false }) product_configurations: any;
	@Prop({ required: false }) page!: Page;

	private quantity: number = 1;
	private selectedProduct: any = null;
	private selectedPeriod: string = "";
	private selectedChildrenService: number = 0;

	$refs!: {
		configurationModalDemo: InstanceType<typeof ModalDemo>;
		configurationModalChildService: InstanceType<typeof ModalChildService>;
	};

	mounted() {
		this.selectedPeriod = "M";
		this.selectedProduct = this.filterProducts(this.product_configurations,this.selectedPeriod)[0].id;
		const currentServices = this.getProductChildrenServices(this.product_configurations, this.selectedProduct)
		if (currentServices.length > 0) {
			const defaultService = currentServices.find((service: any) => service.default)
			if(!defaultService){
				this.selectedChildrenService = currentServices[0].id;
			} else {
				this.selectedChildrenService = defaultService.id;
			}
		}
	}

	filterProducts(products: Array<any>, period: string): Array<any> {
		return products.filter((products: any) => products.period == period);
	}

	setPeriod(period: string): void {
		this.selectedPeriod = period;
		this.selectedProduct = this.filterProducts(this.product_configurations,this.selectedPeriod)[0].id;
	}

	setSelectedProduct(id: number) {
		this.selectedProduct = id;
	}

	getProductPrice(products: any, id: number):number{
		const currentProduct = products.find((products: any) => products.id == id)
		if(currentProduct) return currentProduct.last_rate.price;
		return 0;
	}

	getProductChildrenServices(products: any, id: number):any {
		const currentProduct = products.find((products: any) => products.id == id)
		if(currentProduct) return currentProduct.children;
		return []
	}

	setSelectedChildrenService(args: any) {
		this.selectedChildrenService = args[1][args[0]].id;
	}

	getChildrenServicePrice(childrens: any, id: number):number{
		const currentChild = childrens.find((child: any) => child.id == id)
		if(currentChild) return currentChild.last_rate.price;
		return 0;
	}

	get hasMonthSub() {
		for (const config of this.product_configurations) {
			if (config.ec_subproduct_id && config.period == 'M') {
				return true;
			}
		}
		return false;
	}

	get hasYearSub() {
		for (const config of this.product_configurations) {
			if (config.ec_subproduct_id && config.period == 'A') {
				return true;
			}
		}
		return false;
	}

	get getParents() {
		let res = '';
		for (const index in this.page.parents) {
			if (index == '0') {
				res = this.page.parents[index].A3S_DESCARTPADRE;
			} else if (index == (this.page.parents.length - 1).toString()) {
				res += ' y ' + this.page.parents[index].A3S_DESCARTPADRE;
			} else {
				res += ', ' + this.page.parents[index].A3S_DESCARTPADRE;
			}
		}
		return res;
	}

	formatNumber(value: any) {
		return FormatHelper.formatPrice(value);
	}

	addToShoppingCart() {
		if (process.client) {
			this.$nuxt.$loading.start();
			const order_id = localStorage.getItem('ec_order_id');
			const order = new Order();
			const order_line = new OrderLine();
			order_line.ec_product_configuration_id = this.selectedProduct;
			order_line.quantity = this.quantity;

			let order_line_child: any = null;
			if (this.selectedChildrenService !== 0) {
				order_line_child = new OrderLine();
				order_line_child.ec_product_configuration_id = this.selectedChildrenService;
				order_line_child.parent_ec_product_configuration_id = this.selectedProduct;
				order_line_child.quantity = 1;
			}

			if (order_id) {
				// Editar
				order.lines = JSON.parse(JSON.stringify(this.$store.state.shopping_cart.lines));
				let exist = false;
				for (const stored_order_line of order.lines) {
					if (stored_order_line.ec_product_configuration_id == order_line.ec_product_configuration_id) {
						stored_order_line.quantity += order_line.quantity;
						exist = true;
						break;
					}
				}
				if (!exist) {
					order.lines.push(order_line);
				}
				if (order_line_child) {
					order.lines.push(order_line_child);
				}
				order.id = order_id;
				OrderService.update(this.$axios, order)
					.then((res: any) => {
						if (res.status === 200) {
							this.$nuxt.$loading.finish();
							this.$store.commit('setAlert', {
								show: true,
								message: 'Artículo añadido correctamente',
								type: 'success',
							});
							this.$store.commit('updateShoppingCart', res.data);
							GtmService.emit(this.$gtm, this.$store, 'add_to_cart', this.page, order_line.ec_product_configuration_id);
						}
					})
					.catch((error: any) => {
						this.$nuxt.$loading.finish();
						ApiHelper.handleErrors(error, {}, this.$store);
					});
			} else {
				// Crear, este sería siempre el primer producto del pedido
				order.lines.push(order_line);
				if (order_line_child) {
					order.lines.push(order_line_child);
				}
				OrderService.store(this.$axios, order)
					.then((res: any) => {
						if (res.status === 201) {
							this.$nuxt.$loading.finish();
							this.$store.commit('setAlert', {
								show: true,
								message: 'Artículo añadido correctamente',
								type: 'success',
							});
							localStorage.setItem('ec_order_id', res.data.id);
							this.$store.commit('updateShoppingCart', res.data);
							GtmService.emit(this.$gtm, this.$store, 'add_to_cart', this.page, order_line.ec_product_configuration_id);
						}
					})
					.catch((error: any) => {
						this.$nuxt.$loading.finish();
						ApiHelper.handleErrors(error, {}, this.$store);
					});
			}
		}
	}

	showDemo() {
		this.$refs.configurationModalDemo.show(this.page.title);
	}

	showLiveBeep() {
		if (showLiveBeepChat !== undefined) {
			showLiveBeepChat();
		}
	}
	
	showModalChildService() {
		this.$refs.configurationModalChildService.show();
	}

	modalOptionSelected(option: any) {
		if (option) {
			this.selectedChildrenService = 0;
			this.$refs.configurationModalChildService.hide();
		}
	}
}
