
























import { Component, Vue } from 'vue-property-decorator';

@Component({
	components: {
	},
})
export default class ModalChildService extends Vue {
	selected: any = null;

	$refs!: {
		modal: any;
	};

	show() {
		this.$refs.modal.show();
	}

	hide() {
		this.$refs.modal.hide();
	}

}
