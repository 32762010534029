export default class GtmService {
	public static emit($gtm: any, $store: any, event: string, page: any = null, ec_product_configuration_id: any = null) {
	    this.emitUserIdEvent($gtm, $store);
		const datalayer = {
			event: event,
			ecommerce: {
				cart_id: $store.state.shopping_cart.id ? $store.state.shopping_cart.id : '',
				date_created_cart: $store.state.shopping_cart.created_at ? $store.state.shopping_cart.created_at : '', // Fecha Creación Id de Carrito
				date_update_cart: $store.state.shopping_cart.updated_at ? $store.state.shopping_cart.updated_at : '', // Fecha Última Actualización Id de Carrito
				total_quantity_cart:
					$store.state.shopping_cart.lines && $store.state.shopping_cart.lines.length
						? $store.state.shopping_cart.lines.reduce((acc: number, line: any) => acc + line.quantity, 0)
						: '',
				total_price_cart: $store.state.shopping_cart.total_amount
					? $store.state.shopping_cart.total_amount + $store.state.shopping_cart.total_vat
					: '',
				items: this.buildItemsByEvent(event, page, $store, ec_product_configuration_id)
			},
		};
		if (event == 'purchase') {
			const extra = {
				transaction_id: datalayer.ecommerce.cart_id,
				affiliation: 'Tienda Online',
				value: $store.state.shopping_cart.total_amount,
				tax: $store.state.shopping_cart.total_vat,
				shipping: 0,
				payment_method: 'Tarjeta de crédito',
				coupon: $store.state.shopping_cart.promotional_code_id ? $store.state.shopping_cart.promotional_code_name : null,
				importe_coupon: $store.state.shopping_cart.discount_amount,
				name_customer: btoa($store.state.user.email),
			}
			datalayer.ecommerce = {...datalayer.ecommerce, ...extra};
		}
		// console.log('event', event);
		// console.log('datalayer', datalayer);
		$gtm.push({ ecommerce: null });
        $gtm.push(datalayer);
	}

	private static emitUserIdEvent($gtm: any, $store: any) {
		const datalayer = {
			event: 'User ID',
			user_id: $store.state.user ? $store.state.user.id : '',
			register_date: $store.state.user ? $store.state.user.register_date : '',
			country: 'Spain',
			total_purchases: $store.state.user ? $store.state.user.total_purchases : '',
			total_spent: $store.state.user ? $store.state.user.total_spent : '',
			first_purchase_date: $store.state.user ? $store.state.user.first_purchase_date : '',
			last_purchase_date: $store.state.user ? $store.state.user.last_purchase_date : '',
			name_customer: $store.state.user ? btoa($store.state.user.email): '',
			cart_id: $store.state.shopping_cart.id ? $store.state.shopping_cart.id : '',
			date_created_cart: $store.state.shopping_cart.created_at ? $store.state.shopping_cart.created_at : '',
			date_update_cart: $store.state.shopping_cart.updated_at ? $store.state.shopping_cart.updated_at : '',
			total_quantity_cart: $store.state.shopping_cart.lines && $store.state.shopping_cart.lines.length
				? $store.state.shopping_cart.lines.reduce((acc: number, line: any) => acc + line.quantity, 0)
				: '',
			total_price_cart: $store.state.shopping_cart.total_amount
				? $store.state.shopping_cart.total_amount + $store.state.shopping_cart.total_vat
				: '',
			logged_in: $store.state.user ? 'Yes' : 'No',
		};
		// console.log('user id datalayer', datalayer);
		$gtm.push(datalayer);
	}

	private static buildItemsByEvent(event: string, page: any, $store: any, ec_product_configuration_id: any) {

		if (event == 'view_item' || event == 'select_item') {
			let firstMonthlyPrice = this.hasMonthSub(page.product_configurations);
			let firstYearlyPrice = this.hasYearSub(page.product_configurations);

			let defaultProductPrice = firstMonthlyPrice ? firstMonthlyPrice : firstYearlyPrice;

			let price = defaultProductPrice.last_rate.price;
			let priceWithoutDiscount = defaultProductPrice.last_rate.price;
			let discount = 0;
			if (defaultProductPrice.sale_price) {
				priceWithoutDiscount = firstMonthlyPrice.sale_price;
				discount = ((firstMonthlyPrice.sale_price - price) / firstMonthlyPrice.sale_price) * 100
			}
			return [{
				item_name: page.title,
				item_id: defaultProductPrice.external_ref,
				currency: 'EUR',
				price: price,
				price_without_discount: priceWithoutDiscount,
				discount: discount,
				item_brand: 'A3Satel',
				item_category: page.subcategory_name + ' ' + page.category_name,
				index: 1,
				image: process.env.API_IMG_URL + page.ec_product_image_url,
				is_on_sale: defaultProductPrice.sale_price ? 'yes' : 'no',
				added_date: page.created_at,
			}]
		} else if (event == 'add_to_cart') {
			const selected_basket = $store.state.shopping_cart.lines.find((line: any) => line.ec_product_configuration_id == ec_product_configuration_id);
			const selected_product = page.product_configurations.find((product_config: any) => product_config.id == ec_product_configuration_id);
			if (selected_product) {
				let price = selected_product.last_rate.price;
				let priceWithoutDiscount = selected_product.last_rate.price;
				let discount = 0;
				if (selected_product.sale_price) {
					priceWithoutDiscount = selected_product.sale_price;
					discount = ((selected_product.sale_price - price) / selected_product.sale_price) * 100
				}
				return [{
					item_name: page.title,
					item_id: selected_product.external_ref,
					currency: 'EUR',
					price: price,
					price_without_discount: priceWithoutDiscount,
					discount: discount,
					item_brand: 'A3Satel',
					item_category: page.subcategory_name + ' ' + page.category_name,
					index: 1,
					quantity: selected_basket.quantity,
					image: process.env.API_IMG_URL + page.ec_product_image_url,
					is_on_sale: selected_product.sale_price ? 'yes' : 'no',
					added_date: page.created_at,
					subscription_type: selected_product.period == 'M' ? 'mensual' : 'anual',
					version_type: selected_product.name,
				}]
			}
			return [];
		} else if (event == 'purchase') {
			return $store.state.shopping_cart.lines.map((line: any) => {
				let price = line.last_rate.price;
				let priceWithoutDiscount = line.last_rate.price;
				let discount = 0;
				if (line.sale_price) {
					priceWithoutDiscount = line.sale_price;
					discount = ((line.sale_price - price) / line.sale_price) * 100
				}
				return {
					item_name: line.product_name,
					item_id: line.external_ref,
					currency: 'EUR',
					price: price,
					price_without_discount: priceWithoutDiscount,
					discount: discount,
					item_brand: 'A3Satel',
					item_category: line.subcategory_name + ' ' + line.category_name,
					index: 1,
					quantity: line.quantity,
					image: process.env.API_IMG_URL + line.image,
					is_on_sale: line.sale_price ? 'yes' : 'no',
					added_date: line.added_date,
					subscription_type: line.period == 'M' ? 'mensual' : 'anual',
					version_type: line.product_version,
				}
			});
		}
	}

	private static hasMonthSub(product_configurations: any) {
		for (const config of product_configurations) {
			if (config.ec_subproduct_id && config.period == 'M') {
				return config;
			}
		}
		return false;
	}

	private static hasYearSub(product_configurations: any) {
		for (const config of product_configurations) {
			if (config.ec_subproduct_id && config.period == 'A') {
				return config;
			}
		}
		return false;
	}
}
